exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-animals-js": () => import("./../../../src/pages/projects/animals.js" /* webpackChunkName: "component---src-pages-projects-animals-js" */),
  "component---src-pages-projects-community-js": () => import("./../../../src/pages/projects/community.js" /* webpackChunkName: "component---src-pages-projects-community-js" */),
  "component---src-pages-projects-diaspora-js": () => import("./../../../src/pages/projects/diaspora.js" /* webpackChunkName: "component---src-pages-projects-diaspora-js" */),
  "component---src-pages-projects-eco-js": () => import("./../../../src/pages/projects/eco.js" /* webpackChunkName: "component---src-pages-projects-eco-js" */),
  "component---src-pages-projects-education-js": () => import("./../../../src/pages/projects/education.js" /* webpackChunkName: "component---src-pages-projects-education-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-kids-js": () => import("./../../../src/pages/projects/kids.js" /* webpackChunkName: "component---src-pages-projects-kids-js" */),
  "component---src-pages-projects-veterans-js": () => import("./../../../src/pages/projects/veterans.js" /* webpackChunkName: "component---src-pages-projects-veterans-js" */)
}

